import { reactive, watch, provide, inject, InjectionKey } from 'vue'
import { useRoute } from 'vue-router'

class SimpleLayoutMeta {
  title = ''
  titleEn = ''
}

const useSimpleLayoutMetaKey: InjectionKey<SimpleLayoutMeta> = Symbol('useSimpleLayoutMetaKey')

export function useSimpleLayoutMetaProvide() {
  const route = useRoute()
  /** SimpleLayoutで使用するメタデータ */
  const simpleLayoutMeta = reactive<SimpleLayoutMeta>(new SimpleLayoutMeta())

  provide(useSimpleLayoutMetaKey, simpleLayoutMeta)

  watch(
    route,
    val => {
      /** ルートが切り替わったらリセット */
      Object.assign(simpleLayoutMeta, new SimpleLayoutMeta())

      /** タイトルの設定 */
      simpleLayoutMeta.title = val.meta.hero?.title ?? ''
      simpleLayoutMeta.titleEn = val.meta.hero?.titleEn ?? ''
    },
    { immediate: true, deep: true },
  )

  return simpleLayoutMeta
}

export default function useSimpleLayoutMeta() {
  const simpleLayoutMeta = inject(useSimpleLayoutMetaKey) ?? new SimpleLayoutMeta()

  return simpleLayoutMeta
}
